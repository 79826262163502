import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import "./style.css"; // Updated SCSS file

export default function About() {
  const [open, setOpen] = useState("1");

  const toggle = (id) => {
    setOpen(open === id ? undefined : id);
  };

  return (
    <div className="about-us-container py-5">
      {/* Header Section */}
      <section
        className="text-center py-5"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <h1 className="fw-bold mb-4" style={{ color: "#3BB3C3" }}>
          About Us
        </h1>
        <p className="lead text-muted">
          Welcome to <strong>SteemChat</strong>, where communication meets
          security and simplicity.
        </p>
        <p className="text-muted mx-auto" style={{ maxWidth: "800px" }}>
          Our mission is to provide a seamless, secure, and enjoyable messaging
          experience. Designed with a user-friendly interface and robust privacy
          features, we ensure that staying connected is effortless and
          worry-free.
        </p>
        <p className="text-muted mx-auto" style={{ maxWidth: "800px" }}>
          Built on the Steem blockchain, SteemChat combines cutting-edge
          technology with decentralized principles, offering a platform where
          your conversations are safe, your data is private, and your experience
          is streamlined.
        </p>
        <p className="text-muted mx-auto" style={{ maxWidth: "800px" }}>
          Join the vibrant Steem community and elevate your messaging with
          SteemChat — The ultimate solution for secure and efficient
          communication.
        </p>
      </section>

      {/* Core Developers Section */}
      <section className="core-devs text-center mt-5">
        <h1 className="fw-bold mb-4" style={{ color: "#3BB3C3" }}>
          Meet Our Team
        </h1>
        <div className="team d-flex justify-content-center gap-4 mt-4">
          {[
            {
              name: "blockseater",
              avatar: "https://steemitimages.com/u/blockseater/avatar",
              role: "Core Dev",
              link: "https://steemit.com/@blockseater",
            },
            {
              name: "rme",
              avatar: "https://steemitimages.com/u/rme/avatar",
              role: "Supporter",
              link: "https://steemit.com/@rme",
            },
          ].map(({ name, avatar, role, link }, index) => (
            <div key={index} className="text-center">
              <img
                className="rounded-circle"
                src={avatar}
                alt={name}
                width="80"
                height="80"
              />
              <a
                className="d-block text-decoration-none mt-2"
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                @{name}
              </a>
              <strong className="text-muted">{role}</strong>
            </div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta text-center mt-5">
        <h3 className="fw-bold">
          Want to see more features? Vote for{" "}
          <a
            href="https://steemit.com/@stmpak.wit"
            target="_blank"
            rel="noreferrer"
          >
            @stmpak.wit
          </a>
        </h3>
        <p className="mt-3">
          Collaborator:{" "}
          <a href="https://h4lab.com" target="_blank" rel="noreferrer">
            H4Lab
          </a>
        </p>
      </section>

      {/* FAQ Section */}
      {/* FAQ Section */}
      <section
        className="faq-container mt-5 py-5"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <h2 className="fw-bold text-center mb-4" style={{ color: "#3BB3C3" }}>
          FAQs
        </h2>
        <div className="container">
          <Accordion open={open} toggle={toggle}>
            {[
              {
                id: "1",
                question: "Get Involved",
                answer:
                  "Support our development by voting for @stmpak.wit as a witness on the Steem network.",
              },
              {
                id: "2",
                question: "Collaborators",
                answer:
                  "We're proud to collaborate with H4Lab, bringing innovation and expertise to SteemChat.",
              },
              {
                id: "3",
                question: "What is the app about?",
                answer:
                  "SteemChat is a decentralized messaging platform exclusively for Steem users. It’s a secure space to connect, share, and engage.",
              },
              {
                id: "4",
                question: "Is SteemKeychain Required?",
                answer:
                  "Yes, SteemKeychain is essential for signing, encoding, and decoding messages sent to the Steem blockchain.",
              },
              {
                id: "5",
                question: "How are messages authenticated?",
                answer:
                  "P2P messages are signed using the user’s posting key, and both the backend nodes and the frontend UI verify these messages.",
              },
            ].map(({ id, question, answer }) => (
              <AccordionItem key={id} className="mb-3">
                <AccordionHeader
                  targetId={id}
                  className="fw-semibold"
                  style={{
                    backgroundColor: "#3BB3C3",
                    color: "#fff",
                    padding: " 2px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {question}
                </AccordionHeader>
                <AccordionBody
                  accordionId={id}
                  style={{
                    backgroundColor: "#e8f7f8",
                    color: "#333",
                    padding: "15px",
                    borderRadius: "5px",
                    border: "1px solid #3BB3C3",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {answer}
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </section>
    </div>
  );
}
