import moment from 'moment';
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ProfileModal from './ProfileModal';
import imageProfile from '../../../assets/Images/users/altImage.png';

function CommunityDetailsModal(props) {
    const toggle = () => props.toggleModel();
    const [ProfileModalOpen, setProfileModalOpen] = useState(false);
    const [ProfileDetails, setProfileDetails] = useState({});
    const toggleModel = (element) => {
        setProfileDetails(element)
        setProfileModalOpen(!ProfileModalOpen)
    };
    return (
        <div className=''>
            <Modal isOpen={props.isopened} toggle={toggle} {...props} >
                <ModalHeader toggle={toggle}>
                    <div className="chat-user-img align-self-center me-3 d-flex flex-row px-5" >
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                {props?.details?.title && props.details.title.charAt(0)}
                            </span>
                        </div>
                        <div className='align-self-center mx-2' style={{ color: '#7269ef' }}>{props.details.title}</div>
                    </div>
                </ModalHeader>
                <ModalBody >
                    <ProfileModal isopened={ProfileModalOpen} toggleModel={toggleModel} details={ProfileDetails} centered scrollable />
                    <div className='px-5'>
                        <div className="mt-1">
                            <p className="text-muted mb-1">Account</p>
                            <h5 className="font-size-14">{props.details.account}</h5>
                        </div>
                        <div className="mt-1">
                            <p className="text-muted mb-1">About</p>
                            <h5 className="font-size-14">{props.details.about}</h5>
                        </div>
                        <div className="mt-1">
                            <p className="text-muted mb-1">Description</p>
                            <h5 className="font-size-14">{props.details.description ? props.details.description : 'No Description Found'}</h5>
                        </div>
                        <div className="mt-1">
                            <p className="text-muted mb-1">Rules</p>
                            <h5 className="font-size-14">{props.details.flag_text ? props.details.flag_text : 'No Rules Found'}</h5>
                        </div>
                        <div className="mt-1">
                            <p className="text-muted mb-1">Rank</p>
                            <h5 className="font-size-14">{props.details.rank}</h5>
                        </div>
                        <div className="mt-1">
                            <p className="text-muted mb-1">Subcribers</p>
                            <h5 className="font-size-14">{props.details.count_subs}</h5>
                        </div>
                        <div className="mt-1">
                            <p className="text-muted mb-1">Authors</p>
                            <h5 className="font-size-14">{props.details.count_authors}</h5>
                        </div>
                        <div className="mt-1">
                            <p className="text-muted mb-1">Users & Roles</p>
                            {props?.details?.accounts && props.details.accounts.map((element, i) => {
                                return <h5 key={i} className="font-size-14" onClick={() => { toggleModel(element) }} style={{ cursor: 'pointer', color: '#7269ef' }}>
                                    <img
                                        src={element.cover_image ?
                                            element.cover_image :
                                            (element.posting_json_metadata && JSON.parse(element.posting_json_metadata)?.profile?.profile_image) ?
                                                JSON.parse(element.posting_json_metadata).profile.profile_image : imageProfile}
                                        className="rounded-circle chat-user-img avatar-xs me-3"
                                        alt="Steemit"
                                    /> {element.name ? element.name : element.altName} ({element.role[0][3]}) {element.role[0][2] && (element.role[0][2])}
                                </h5>
                            })}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default CommunityDetailsModal;