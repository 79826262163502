// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
  font-family: "Inter", sans-serif;
  color: #333;

  .core-devs img {
    border: 2px solid #ddd;
    transition: transform 0.3s ease;
  }
  .core-devs img:hover {
    transform: scale(1.1);
  }

  .team a {
    font-weight: bold;
    color: #007bff;
  }

  .faq-container .accordion {
    max-width: 800px;
    margin: auto;
  }

  .cta a {
    color: #007bff;
    font-weight: bold;
  }
}
.faq-container .accordion-header {
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.faq-container .accordion-header:hover {
  background-color: #32a3b3;
  transform: scale(1.02);
}
.faq-container .accordion-body {
  transition: box-shadow 0.3s ease;
}
.faq-container .accordion-body:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/About/style.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,WAAW;;EAEX;IACE,sBAAsB;IACtB,+BAA+B;EACjC;EACA;IACE,qBAAqB;EACvB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;AACF;AACA;EACE,2DAA2D;AAC7D;AACA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,gCAAgC;AAClC;AACA;EACE,2CAA2C;AAC7C","sourcesContent":[".about-us-container {\n  font-family: \"Inter\", sans-serif;\n  color: #333;\n\n  .core-devs img {\n    border: 2px solid #ddd;\n    transition: transform 0.3s ease;\n  }\n  .core-devs img:hover {\n    transform: scale(1.1);\n  }\n\n  .team a {\n    font-weight: bold;\n    color: #007bff;\n  }\n\n  .faq-container .accordion {\n    max-width: 800px;\n    margin: auto;\n  }\n\n  .cta a {\n    color: #007bff;\n    font-weight: bold;\n  }\n}\n.faq-container .accordion-header {\n  transition: background-color 0.3s ease, transform 0.2s ease;\n}\n.faq-container .accordion-header:hover {\n  background-color: #32a3b3;\n  transform: scale(1.02);\n}\n.faq-container .accordion-body {\n  transition: box-shadow 0.3s ease;\n}\n.faq-container .accordion-body:hover {\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
