import React from "react";
import Lottie from "lottie-react";
import loading from "../assets/animations/Loading.json";
import SteemLogo from "../assets/Images/webIcon.png";
function LoadingScreen() {
  return (
    <div className="LoadingScreen">
      <img src={SteemLogo} alt="" height="100" className="logo logo-dark" />
      {/* <Lottie animationData={loading} loop={true} style={{ height: 150 }} /> */}
      <h2 style={{ marginTop: 20 }}>Loading ...</h2>
    </div>
  );
}

export default LoadingScreen;
