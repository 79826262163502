import moment from 'moment';
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SteemLogo from "../assets/Images/webIcon.png";
function InAppLoading(props) {
    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>
                Click Me
            </Button> */}
            <Modal isOpen={props.isopened} centered={true}>
                <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '40%' }}>
                    <img
                        src={SteemLogo}
                        alt=""
                        height="100"
                        className="logo logo-dark"
                    />
                    {/* <Lottie animationData={loading} loop={true} style={{ height: 150 }} /> */}
                    <h2 style={{ marginTop: 20 }}>
                        Loading ...
                    </h2>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default InAppLoading;