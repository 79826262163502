import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Label,
  Form,
} from "reactstrap";
import EmojiPicker from "emoji-picker-react";
import { AppContext } from "../../../StateManagement/AppContext";
import imageProfile from "../../../assets/Images/users/altImage.png";
import { Mention, MentionsInput } from "react-mentions";
import axios from "axios";
import CryptoJS from "crypto-js";


function ChatInput(props) {
  // console.log("🚀 => props:", props);
  const { userData, userDetails, socket } = useContext(AppContext);
  const [profileImg, setprofileImg] = useState(
    userDetails.posting_json_metadata
      ? JSON.parse(userDetails.posting_json_metadata).profile.profile_image
      : ""
  );
  const [textMessage, settextMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [showReply, setShowReply] = useState(false);
  const [imageName, setImageName] = useState(false);
  // console.log("🚀 ~ ChatInput ~ showReply:", showReply);
  const [file, setfile] = useState({
    name: "",
    size: "",
  });
  const [inputFile, setInputFile] = useState(null);
  const [fileImage, setfileImage] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const [showImage, setShowImage] = useState(false);
  // console.log("🚀 ~ ChatInput ~ file:", inputFile);
  // console.log("🚀 ~ ChatInput ~ fileImage:", fileImage);

  const toggle = () => setisOpen(!isOpen);


  //function for text input value change
  const handleChange = (e) => {
    settextMessage(e.target.value);
    if (!isTyping) {
      userTyping();
      setIsTyping(true);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    // console.log(emojiObject,event);
    settextMessage(textMessage + event.emoji);
  };

  //function for file input change
  const handleFileChange = (e) => {
    if (e.target.files.length !== 0)
      setfile({
        name: e.target.files[0].name,
        size: e.target.files[0].size,
      });
  };

  // ~ for spinning loader
  // Add this near the top of your file, after the imports
  const spinnerStyle = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
`;

  // Add this inside your component, before the return statement
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = spinnerStyle;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  //function for image input change
  const handleImageChange = async (currentFile) => {
    // const file = e.target.files[0];
    setInputFile(currentFile);
    // console.log("🚀 ~ handleImageChange ~ file:", currentFile);
    setImageName(currentFile?.name);

    // if (file && file.type.startsWith("image/")) {
    //   const imageUrl = URL.createObjectURL(file);
    //   setfileImage(imageUrl);

    //   // Optional: revoke URL after usage
    //   return () => URL.revokeObjectURL(imageUrl);
    // } else {
    //   console.error("Selected file is not an image.");
    // }
    if (currentFile && currentFile.type.startsWith("image/")) {
      const formData = new FormData();
      formData.append("image", currentFile); // Append the image file

      try {
        setImageLoading(true);
        const response = await axios.post(
          "https://api.imgbb.com/1/upload?key=fa12b9c60b4c4a630acc7018183f3b22",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log(response.data); // Log the response data
        setfileImage(response.data?.data?.image?.url);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setImageLoading(false);
      }
    } else {
      console.error("Selected file is not an image.");
    }
  };

  //~ function for send data to onaddMessage function(in userChat/index.js component)
  const onaddMessage = (e, textMessage) => {
    e.preventDefault();
    if (textMessage !== "" && fileImage === "") {
      if (props.reply) {
        props.onaddMessage(textMessage, "replyMessage", props.reply);
        settextMessage("");
        props.removeReply();
      } else {
        props.onaddMessage(textMessage, "textMessage");
        settextMessage("");
      }
    }
    if (file.name !== "") {
      props.onaddMessage(file, "fileMessage");
      setfile({
        name: "",
        size: "",
      });
    }
    if (fileImage !== "") {
      props.onaddMessage(textMessage, "imageMessage", fileImage);
      setfileImage("");
      setInputFile(null);
    }
  };
  const userName = localStorage.getItem("userName");

  const userTyping = () => {
    try {
      if (userData) {
        let Details = userData;
        const messageToRoom = {
          id: props.chatMessages.length + 10,
          message: "",
          userType: "receiver",
          status: "3",
          userName: userDetails.name
            ? userDetails.name
            : userDetails.posting_json_metadata
            ? JSON.parse(userDetails.posting_json_metadata).profile.name
            : userName || "Unknown User",
          profilePicture: profileImg ? profileImg : imageProfile,
          isFileMessage: false,
          isImageMessage: false,
          isTyping: true,
        };
        if (Details.isGroup) {
          socket?.emit("userTyping", {
            groupName: Details.groups.filter(
              (a) => a.groupId === userData.active_group
            )[0].name,
            messageToRoom,
          });
        } else {
          socket?.emit("userTypingPeer", {
            groupName: Details.recentChatList.filter(
              (a) => a.id === userData.active_user
            )[0]?.chat_id,
            messageToUser: messageToRoom,
          });
        }
      }
    } catch (e) {}
  };

  const userTypingStopped = () => {
    try {
      if (userData) {
        let Details = userData;
        const messageToRoom = {
          id: props.chatMessages.length + 10,
          message: "",
          userType: "receiver",
          status: "3",
          userName: userDetails.name
            ? userDetails.name
            : userDetails.posting_json_metadata
            ? JSON.parse(userDetails.posting_json_metadata).profile.name
            : userName || "Unknown User",
          profilePicture: profileImg ? profileImg : imageProfile,
          isFileMessage: false,
          isImageMessage: false,
          isTyping: true,
          isTypingStopped: true,
        };

        if (Details?.isGroup) {
          socket?.emit("userTyping", {
            groupName: Details.groups.filter(
              (a) => a.groupId === userData.active_group
            )[0].name,
            messageToRoom,
          });
        } else {
          socket?.emit("userTypingPeer", {
            groupName: Details.recentChatList.filter(
              (a) => a.id === userData.active_user
            )[0]?.chat_id,
            messageToUser: messageToRoom,
          });
        }
      }

      setIsTyping(false);
    } catch (e) {}
  };

  useEffect(() => {
    const delayFn = setTimeout(() => userTypingStopped(), 500);

    return () => clearTimeout(delayFn);
  }, [textMessage]);

  const usersList = (query) => {
    return userData?.groups
      ?.filter((e) => {
        return e.groupId === userData?.active_group;
      })[0]
      ?.userRoles?.filter((element) => {
        return element?.id.startsWith(query);
      })
      .slice(0, 10);
  };

  useEffect(() => {
    if (props?.reply) {
      setShowReply(true);
    }
  }, [props]);

  //~ handle removing image link and input file
  const handleRemoveFile = () => {
    setInputFile(null);
    setfileImage("");
  };

  // console.log("🚀 => showImage:", showImage);
  return (
    <React.Fragment>
      {/* showing the image to full screen when user click the image */}
      {showImage && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#E6EBF5",
            border: "2px solid black",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0",
            left: "0",
            padding: "5%",
            position: "fixed",
            zIndex: "999999",
          }}
        >
          {/* handling loading state */}
          {imageLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100px",
                height: "80px",
                borderRadius: "20px",
                objectFit: "cover",
                backgroundColor: "#F8F9FA",
                padding: "5px",
              }}
            >
              <div className="spinner"></div>
            </div>
          ) : (
            <div
              //  main container for button and image
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "3vh",
              }}
            >
              {/* container for the button */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  color: "inherit",
                  fontSize: "20px",
                }}
              >
                <button
                  onClick={() => setShowImage(false)}
                  style={{
                    width: "full",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    border: "none",
                    borderRadius: "5px",
                    backgroundColor: "transparent",
                    color: "inherit",
                  }}
                >
                  x
                </button>
              </div>
              <img
                src={fileImage}
                alt="Preview"
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "10px",
                  objectFit: "contain",
                  padding: "5px",
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className="p-1 p-lg-2 border-top">
        <Form onSubmit={(e) => onaddMessage(e, textMessage)}>
          <Row className="g-0">
            <Col>
              <div className="d-flex flex-column position-relative">
                {inputFile && inputFile !== null && (
                  <div
                    className="fileFoundClass"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        alignItems: "end",
                        gap: "3px",
                        width: "auto",
                        height: "auto",
                        position: "absolute",
                        bottom: "5px",
                        right: "0",
                        zIndex: "1000",
                        padding: "0 10px",
                      }}
                    >
                      {imageLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            width: "100px",
                            height: "80px",
                            borderRadius: "20px",
                            objectFit: "cover",
                            backgroundColor: "#F8F9FA",
                            padding: "5px",
                          }}
                        >
                          <div className="spinner"></div>
                        </div>
                      ) : (
                        <>
                          {/* cross button */}
                          <button
                            onClick={handleRemoveFile}
                            style={{
                              width: "full",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "end",
                              border: "none",
                              borderRadius: "5px",
                              backgroundColor: "transparent",
                              color: "inherit",
                            }}
                          >
                            x
                          </button>
                          {/* input file */}
                          <img
                            onClick={() => setShowImage(true)}
                            src={fileImage}
                            alt="Preview"
                            style={{
                              width: "100px",
                              height: "80px",
                              borderRadius: "10px",
                              objectFit: "cover",
                              backgroundColor: "#7269EF",
                              padding: "5px",
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
                <MentionsInput
                  type="text"
                  classNames={{
                    mentions__input: "form-control",
                    mentions__suggestions__item: "form-control rounded-0",
                  }}
                  singleLine
                  forceSuggestionsAboveCursor={true}
                  value={textMessage}
                  style={{
                    display: "flex",
                    innerHeight: "auto",
                  }}
                  onChange={handleChange}
                  placeholder="Enter Message..."
                >
                  <Mention
                    data={userData?.isGroup ? usersList : [] || []}
                    appendSpaceOnAdd
                    markup="@*__display__*"
                    // onAdd={(id, display, startPos, endPos) => console.log(id, display, startPos, endPos)}
                    displayTransform={(id, display) => `@${display}`}
                  />
                </MentionsInput>
              </div>
              {props?.reply && showReply && (
                <div
                  className="d-flex w-100 bg-white justify-content-between align-items-center p-4 replyBar border-1 border py-2 mb-1"
                  style={{}}
                >
                  <div
                    style={{
                      borderLeft: "4px solid rgb(114, 105, 239)",
                      borderRadius: "2px",
                    }}
                    className="d-flex px-1 "
                  >
                    <div className="d-flex flex-column align-content-center justify-content-center">
                      <h5
                        className="d-flex"
                        style={{ color: "rgb(114, 105, 239)" }}
                      >
                        {props.reply.userName}
                      </h5>
                      <p className="mb-0">{props.reply.message}</p>
                    </div>
                  </div>
                  <i
                    className="ri-close-line"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowReply(false);
                    }}
                  ></i>
                </div>
              )}
            </Col>
            <Col xs="auto">
              <div className="chat-input-links ms-md-2">
                <ul className="list-inline mb-0 ms-0">
                  {/* <EmojiPicker height={500} width={400} /> */}
                  {/* <EmojiPicker onEmojiClick={onEmojiClick}  /> */}
                  <li className="list-inline-item">
                    <ButtonDropdown
                      className="emoji-dropdown"
                      direction="up"
                      isOpen={isOpen}
                      toggle={toggle}
                    >
                      <DropdownToggle
                        id="emoji"
                        color="link"
                        className="text-decoration-none font-size-16 btn-lg waves-effect"
                      >
                        <i className="ri-emotion-happy-line"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end emojiDropdown">
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                      </DropdownMenu>
                    </ButtonDropdown>
                    <UncontrolledTooltip target="emoji" placement="top">
                      Emoji
                    </UncontrolledTooltip>
                  </li>
                  {/* <li className="list-inline-item input-file">
                                        <Label id="files" className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                            <i className="ri-attachment-line"></i>
                                            <Input onChange={(e) => handleFileChange(e)} type="file" name="fileInput" size="60" />
                                        </Label>
                                        <UncontrolledTooltip target="files" placement="top">
                                            Attached File
                                        </UncontrolledTooltip>
                                    </li> */}
                  <li className="list-inline-item input-file">
                    <Label
                      id="images"
                      className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect position-relative"
                    >
                      {/* {inputFile && inputFile !== null && (
                        <div className="position-absolute image-found-badge">
                          Image found
                        </div>
                      )} */}
                      <i className="ri-image-fill"></i>
                      <Input
                        // onChange={(e) => handleImageChange(e)}
                        onChange={(e) => handleImageChange(e.target.files[0])}
                        accept="image/*"
                        type="file"
                        name="fileInput"
                        size="60"
                      />
                    </Label>
                    <UncontrolledTooltip target="images" placement="top">
                      Images
                    </UncontrolledTooltip>
                  </li>
                  <li className="list-inline-item">
                    <Button
                      type="submit"
                      color="primary"
                      style={{
                        backgroundColor:"#3BB3C3",
                        border:'none'
                      }}
                      className="font-size-16 btn-lg chat-send waves-effect waves-light"
                    >
                      <i className="ri-send-plane-2-fill"></i>
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
}

export default ChatInput;
